<template>
	<b-row style="height:100%;">
		<b-col lg="5" class="p-0">
			<router-view></router-view>
		</b-col>
		<b-col lg="7" class="p-0 d-none d-lg-block">
			<b-carousel id="carousel-1" fade :interval="4000" background="#ababab">
				<b-carousel-slide img-src="/media/login-slides/Maritime.png"></b-carousel-slide>
				<b-carousel-slide img-src="/media/login-slides/miningoperation.jpg"></b-carousel-slide>
				<b-carousel-slide img-src="/media/login-slides/rigwithboat.jpg"></b-carousel-slide>
				<b-carousel-slide img-src="/media/login-slides/cruiseship.jpg"></b-carousel-slide>
				<b-carousel-slide img-src="/media/login-slides/mining2.jpg"></b-carousel-slide>
				<b-carousel-slide img-src="/media/login-slides/vesselcloudy.jpg"></b-carousel-slide>
			</b-carousel>
		</b-col>
	</b-row>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import '@/assets/sass/pages/login/login-1.scss';
</style>

<script>
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

export default {
	name: 'auth',
	methods: {},
	computed: {
		...mapGetters(['isAuthenticated']),
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Login' }]);
	},
	created() {
		if (this.isAuthenticated) {
			this.$router.replace('/');
		}
	},
};
</script>

<style>
.carousel-item img {
	height: 100vh;
	object-fit: cover;
	object-position: 100% 0;
}
</style>
